import { forwardRef } from 'react';

import { mapClassNamesToSlots } from '../../../style-system';
import { TVComponentWithElementProps } from '../../../style-system/models';
import { IconComponent } from '../../icon';
import { type SimpleFieldOptions, useFieldProps } from '../field/context';

import { inputStyles } from './input.styles';

type InputProps = Omit<
    TVComponentWithElementProps<'input', typeof inputStyles, keyof SimpleFieldOptions>,
    'hasIcon'
> & {
    icon?: IconComponent;
} & SimpleFieldOptions;

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
    { variant = 'outline-transparent', noOutline, icon: Icon, w, classNames, type = 'text', ...rest },
    ref,
) {
    const { size, fieldProps, rootProps } = useFieldProps(rest);
    const { base, icon, input } = mapClassNamesToSlots(inputStyles, {
        hasIcon: !!Icon,
        noOutline,
        variant,
        size,
        disabled: fieldProps.disabled,
        readOnly: fieldProps.readOnly,
        w,
        classNames,
    });

    return (
        <div {...rootProps} className={base}>
            <input ref={ref} {...fieldProps} type={type} value={rest.value ?? undefined} className={input} />
            {Icon && <Icon className={icon} />}
        </div>
    );
});

export type { InputProps };
export { Input };
