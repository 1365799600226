import { Avatar, List, Text } from '@blockworks/ui/components';

import { AppRoutes } from '@/api/auth/auth.routes';

import { ItemTypes } from '../utils/search.enums';
import { AuthorHit, SearchHitProps } from '../utils/search.types';

export const AuthorItem = (props: SearchHitProps<AuthorHit>) => {
    const { objectID, name, slug, image, onSelect } = props;
    const itemData = { objectID, name, slug, image } as AuthorHit;
    const href = AppRoutes.TEAM_SLUG.pathname(slug as string);

    return (
        <List.Row id={objectID} onClick={() => onSelect?.(itemData, ItemTypes.Author)} href={href}>
            <List.Col size="content">
                {image?.url && <Avatar size={32} alt={name} src={image.url as string} />}
            </List.Col>
            <List.Col size="content">
                <Text size="sm" weight="semibold">
                    {name}
                </Text>
            </List.Col>
        </List.Row>
    );
};
