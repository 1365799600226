import { createQuery } from 'react-query-kit';
import { keyBy, uniq } from 'lodash-es';

import { AssetFieldParam, assetsApi } from '@blockworks/platform/api/research/assets';
import { AnyStrapiClient, Dashboard, DataPage } from '@blockworks/platform/api/strapi';

import { getAssetLogo } from '@/utils/logos';

import { DashboardEntry } from './dashboard-queries.model';

export const createDashboardQueries = (client: AnyStrapiClient) => {
    const useGetAllDashboards = createQuery({
        queryKey: ['all-dashboards'],
        fetcher: async () => {
            const { data: allDashboards } = await client.get.dashboards();

            const assets: string[] = [];
            allDashboards.forEach(({ tag }) => {
                if (tag?.isAsset && !assets.includes(tag?.slug!)) {
                    assets.push(tag.slug!);
                }
            });
            const response = await assetsApi.get.assets({
                assets,
                limit: 50,
                fields: [AssetFieldParam.Title, AssetFieldParam.Code, AssetFieldParam.ImageUrl],
            });

            const data = response?.data;

            const assetDashboards: Record<string, DashboardEntry> = keyBy(
                data.map(asset => ({ ...asset, code: asset.code!, isAsset: true, dashboards: [] as Dashboard[] })),
                'code',
            );
            const nonAssetDashboards: Record<string, DashboardEntry> = {};

            allDashboards.forEach(dashboard => {
                const { tag } = dashboard;
                if (!tag) {
                    nonAssetDashboards.Spotlight = {
                        title: dashboard.title!,
                        code: dashboard.slug!,
                        dashboards: [dashboard],
                        isAsset: false,
                    };
                } else if (assetDashboards[tag.name]) {
                    assetDashboards[tag.name]?.dashboards.push(dashboard);
                } else {
                    nonAssetDashboards[tag.name] = {
                        title: tag.name,
                        code: tag.slug!,
                        dashboards: [dashboard],
                        isAsset: false,
                    };
                }
            });
            return [...Object.values({ ...assetDashboards, ...nonAssetDashboards })];
        },
        staleTime: 1000 * 60 * 60,
    });

    const useGetDashboards = createQuery({
        queryKey: ['dashboards'],
        fetcher: client.get.dashboards,
        staleTime: 1000 * 60 * 60,
    });

    const useGetDashboardCategories = createQuery({
        queryKey: ['dashboard-categories'],
        fetcher: async () => {
            const { data: datapage } = await client.get.dataPage();

            const groupedByCategory: Record<string, string[]> = {};

            for (const item of datapage?.tabs || []) {
                const category = item?.category;
                if (category) {
                    if (!groupedByCategory[category]) {
                        groupedByCategory[category] = [];
                    }
                    groupedByCategory[category]?.push(item.slug);
                }
            }

            return groupedByCategory;
        },
        staleTime: 1000 * 60 * 60,
    });

    const useGetDatapage = createQuery({
        queryKey: ['dashboard-page'],
        fetcher: async () => {
            const { data: datapage } = await client.get.dataPage();

            const categories = uniq(datapage?.tabs.map(tab => tab.category)).filter(Boolean);
            const formatDashboard = (tab: DataPage['tabs'][number]) => {
                const tagSlugs =
                    uniq(tab.dashboards.filter(({ tag }) => tag?.isAsset).map(({ tag }) => tag?.slug)) ?? [];
                const assetTag = tagSlugs?.at?.(0);
                const initialDashboard = tab.dashboards.at(0);

                const href = assetTag
                    ? `/analytics/${assetTag}?dashboard=${initialDashboard?.slug}`
                    : `/analytics/${tab.slug}`;

                return {
                    ...tab,
                    href,
                    assetImage: getAssetLogo(assetTag ?? '') ?? null,
                };
            };

            // Temporary exclusion before cutover
            const tabs = datapage?.tabs.filter(tab => tab.slug !== 'spotlight')?.map(formatDashboard);
            const spotlight = datapage?.spotlight?.map?.(formatDashboard);

            return {
                categories: categories as string[],
                bySlug: keyBy(tabs, 'slug'),
                spotlightBySlug: keyBy(spotlight, 'slug'),
            };
        },
        staleTime: 1000 * 60 * 60,
    });

    return {
        getDashboardCategories: {
            use: useGetDashboardCategories,
        },
        getDashboards: {
            use: useGetDashboards,
        },
        getAllDashboards: {
            use: useGetAllDashboards,
        },
        getDatapage: { use: useGetDatapage },
    };
};
