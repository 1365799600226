import React, { PropsWithChildren } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import { TVStylePropsWithoutClassName } from '../../style-system';

import { tooltipStyles } from './tooltip.styles';

export interface TooltipProps
    extends TVStylePropsWithoutClassName<typeof tooltipStyles>,
        Pick<RadixTooltip.PopperContentProps, 'side' | 'align'> {
    children: React.ReactNode;
    triggerText?: string;
    contentText?: string;
}

const tooltipContext = React.createContext<Pick<TooltipProps, 'variant' | 'side' | 'align'>>({
    variant: 'default',
    side: 'right',
    align: 'center',
});

const TooltipProvider = tooltipContext.Provider;

const useTooltipContext = () => React.useContext(tooltipContext);

const Trigger = ({ children }: PropsWithChildren) => {
    return <RadixTooltip.Trigger>{children}</RadixTooltip.Trigger>;
};

const Content = ({ children }: PropsWithChildren) => {
    const { variant, side, align } = useTooltipContext();
    const { base, arrow } = tooltipStyles({ variant });
    return (
        <RadixTooltip.Portal>
            <RadixTooltip.Content side={side} align={align} className={base()} sideOffset={2}>
                {children}
                <RadixTooltip.Arrow width={10} height={5} className={arrow()} />
            </RadixTooltip.Content>
        </RadixTooltip.Portal>
    );
};

export const Tooltip = ({ children, triggerText, contentText, ...rest }: TooltipProps) => {
    const { variant = 'default', side = 'right', align = 'center' } = { ...rest };
    if (triggerText && contentText && children) {
        return <p>Tooltip cannot have triggerText, contentText and children defined</p>;
    }
    return (
        <TooltipProvider value={{ variant, side, align }}>
            <RadixTooltip.Provider delayDuration={200}>
                <RadixTooltip.Root>
                    {triggerText && <Trigger>{triggerText}</Trigger>}
                    {contentText && <Content {...rest}>{contentText}</Content>}
                    {children}
                </RadixTooltip.Root>
            </RadixTooltip.Provider>
        </TooltipProvider>
    );
};

Tooltip.Trigger = Trigger;

Tooltip.Content = Content;
