import * as React from 'react';

import { Avatar, FlexBox, List, Tag, Text } from '@blockworks/ui/components';

import { AppRoutes } from '@/api/auth/auth.routes';
import { getAssetLogo } from '@/utils/logos';

import { ItemTypes } from '../utils/search.enums';
import { AssetHit, SearchHitProps } from '../utils/search.types';

const AssetItem = (props: SearchHitProps<AssetHit>) => {
    const { objectID, code, image, title, onSelect } = props;
    const itemData = { objectID, code, image, title } as AssetHit;
    const imageSrc = image ?? getAssetLogo(code);
    const href = AppRoutes.ASSETS_SLUG.pathname(code?.toLowerCase());

    return (
        <List.Row id={objectID} onClick={() => onSelect?.(itemData, ItemTypes.Asset)} href={href}>
            <List.Col size="content">{imageSrc && <Avatar size={32} alt={title} src={imageSrc} />}</List.Col>
            <List.Col size="content" grow>
                <FlexBox col>
                    <Text size="sm" weight="semibold">
                        {title}
                    </Text>
                </FlexBox>
            </List.Col>
            <List.Col size="content">
                <Tag>{code}</Tag>
            </List.Col>
        </List.Row>
    );
};

export { AssetItem };
