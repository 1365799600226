import { HTMLProps, useId, useState } from 'react';
import Image, { ImageProps } from 'next/image';

import { TVStyleProps, TVStylePropsWithoutClassName } from '../../style-system';

import { avatarGroupStyles, avatarStyles } from './avatar.styles';

type AvatarProps = Omit<HTMLProps<HTMLImageElement>, 'size' | 'as' | 'className'> &
    TVStyleProps<typeof avatarStyles, true> & {
        as?: React.ComponentType<ImageProps> | 'img';
        href?: string;
    };

const Avatar = ({
    src,
    size = 32,
    style,
    stacked,
    alt = '',
    as: AsComponent = Image,
    href,
    contrast,
    className,
}: AvatarProps) => {
    const [status, setStatus] = useState('ready');
    const As = href ? 'a' : 'div';

    const styles = avatarStyles({ size, stacked });
    const frameClassname = styles.base({ size, stacked, contrast, className });

    if (!src) {
        return (
            <As className={frameClassname} href={href} style={style}>
                <div
                    className={styles.image({
                        ghost: true,
                    })}
                />
                <div className={styles.placeholder()}>{alt.charAt(0)}</div>
            </As>
        );
    }

    return (
        <As className={frameClassname} href={href} style={style}>
            <AsComponent
                src={src}
                alt={alt}
                width={size}
                height={size}
                placeholder="empty"
                className={styles.image({
                    ghost: status === 'error',
                })}
                onError={() => setStatus('error')}
            />
            <div
                className={styles.placeholder({
                    ghost: !!(status !== 'error' && src),
                })}
            >
                {alt.charAt(0)}
            </div>
        </As>
    );
};

type AvatarGroupProps = TVStylePropsWithoutClassName<typeof avatarGroupStyles> & {
    children?: never;
    avatars?: Omit<AvatarProps, 'size' | 'as'>[];
    stack: 'asc' | 'desc';
};

const AvatarGroup = ({ size = 32, avatars = [], stack }: AvatarGroupProps) => {
    const id = useId();
    const zDirection = stack === 'desc' ? -1 : 1;
    if (avatars.length === 0) {
        return null;
    }
    return (
        <div className={avatarGroupStyles({ size })}>
            {avatars.map((child, idx) => {
                const elementProps = {
                    ...child,
                    stacked: true,
                    size,
                    style: {
                        zIndex: `${idx * zDirection}`,
                    },
                };
                return <Avatar key={`${id}-${child.alt}`} {...elementProps} />;
            })}
        </div>
    );
};

export type { AvatarGroupProps, AvatarProps };
export { Avatar, AvatarGroup };
