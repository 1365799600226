import { forwardRef } from 'react';

import { cn } from '../cn';
import { As } from '../models';

import { FactoryComponent, type FactoryProps, type FactoryPropsWithRef } from './factory.component';

const makeFactoryComponent = <T extends As>(as: T, props?: FactoryProps<T>) =>
    forwardRef<React.ElementRef<any>, FactoryPropsWithRef<any>>(function Factory(restProps, ref) {
        return (
            <FactoryComponent
                as={as}
                {...(props || {})}
                {...restProps}
                ref={ref}
                className={cn(props?.className, restProps?.className)}
            />
        );
    }) as unknown as typeof FactoryComponent<T>;

type ReturnBit<T extends As> = ReturnType<typeof makeFactoryComponent<T>>;

type BitFactory = {
    <T extends As>(as: T, props?: FactoryPropsWithRef<T>): ReturnBit<T>;
} & {
    [T in As]: ReturnBit<T>;
};

const factory = () => {
    const cache = new Map();

    return new Proxy(makeFactoryComponent, {
        /**
         * @example
         * const Anchor = Bit("a", { href: 'http://mylink.com' })
         * const MyComponent = Bit(Component)
         */
        apply: <T extends As>(_target: any, _thisArg: any, [as, props]: [T, FactoryPropsWithRef<T>]) => {
            return makeFactoryComponent(as, props);
        },
        /**
         * @example
         * <Bit.div />
         * <Bit.span />
         * <Bit.a />
         * <Bit.section />
         */
        get: <T extends As>(_: any, as: T) => {
            if (!cache.has(as)) {
                cache.set(as, makeFactoryComponent(as));
            }
            return cache.get(as);
        },
    }) as unknown as BitFactory;
};

/**
 * A low-level primitive API to implement UI elements.
 * @example
 *
 * // define components inline with jsx
 * <Bit.div />
 * <Bit.span />
 * <Bit.a />
 * <Bit.section />
 *
 * // or define components outside of JSX with functions
 * const Anchor = Bit("a", { href: 'http://mylink.com' })
 * const MyWrapper = Bit(Component)
 * () => {
 *  return (
 *      <MyWrapper>
 *         <Anchor>Link</Anchor>
 *      </MyWrapper>
 *  );
 * }
 *
 */
const Bit = factory();
type BitProps<T extends As> = FactoryProps<T>;
type BitPropsWithRef<T extends As> = FactoryPropsWithRef<T>;

export type { BitProps, BitPropsWithRef };
export { Bit };
