import Stripe from 'stripe';

import { defineEndpoints } from '../../config/define-endpoints';
import { DataWithMeta } from '../../config/type-helpers';

type UserProfileShape = {
    theme?: 'Dark' | 'Light' | 'System';
    industry?: string;
    title?: string;
    discordUsername?: string;
    userId: number;
    updatedAt: number;
};

type GetUserByIdResponse = DataWithMeta<{
    name: string;
    firstname: string;
    lastname: string;
    email: string;
    image: string;
    phoneNumber: string;
    lastLogin?: number | null;
    company: {
        id: number;
        name: string;
    };
    authProvider: string;
    subscription: string;
    subscriptionStatus: Stripe.Subscription.Status;
    customerId: string;
}>;

type GetUserProfileResponse = DataWithMeta<UserProfileShape>;

type UpdateUserProfileResponse = DataWithMeta<{
    status: boolean;
}>;

type UpdateUserProfileRequest = { body: Partial<UserProfileShape> & { userId: number }; sessionToken: string };

type UpdateUserAccountRequest = {
    firstname: string;
    lastname: string;
    email: string;
};

type UpdateUserAccountResponse = DataWithMeta<{
    status: number;
    userId: number;
}>;

type UpdateUserImageRequest = {
    file: File;
};

type UpdateUserImageResponse = DataWithMeta<{
    imageUrl: string;
    status: number;
}>;

const USER_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        USER_ACCOUNT: template('/api/v1/user-account'),
        USER_PROFILE: template('/api/v1/user-profile'),
        USER_BY_ID: template('/api/v1/user/{id}'),
        USER_BY_SESSION: template('/api/v1/user/session/{sessionId}'),
        USER_IMAGE: template('/api/v1/user-image'),
        SESSION: template('/api/v1/user'),
    },
    POST: {
        UPDATE_ACCOUNT: template('/api/v1/user-account'),
        UPDATE_PROFILE: template('/api/v1/user-profile'),
        UPDATE_USER_IMAGE: template('/api/v1/user-image'),
    },
    PATCH: {
        UPDATE_PROFILE: template('/api/v1/user-profile'),
    },
}));

export type {
    GetUserByIdResponse,
    GetUserProfileResponse,
    UpdateUserAccountRequest,
    UpdateUserAccountResponse,
    UpdateUserImageRequest,
    UpdateUserImageResponse,
    UpdateUserProfileRequest,
    UpdateUserProfileResponse,
};

export { USER_ENDPOINTS };
