import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

export const iconButtonStyles = tVariants({
    base: 'inline-flex box-border cursor-pointer transition-colors items-center justify-center',
    slots: {
        icon: 'text-current',
    },
    defaultVariants: {
        size: 'md',
        variant: 'fill',
        intent: 'neutral',
    },
    variants: {
        borderRadius: factoryStyleProps.border.borderRadius,
        ...factoryStyleProps.layout,
        ...factoryStyleProps.size,
        ...factoryStyleProps.__atoms.space.margin,
        variant: {
            fill: 'border-transparent',
            ghost: 'bg-current-bg border border-transparent',
        },
        /**
         * We don't have a clarified color system to use intent/color and variants optimally.
         * So let's leave these empty and purely manipulate these classes in compoundVariants.
         **/
        intent: {
            primary: '',
            neutral: '',
        },
        size: {
            xs: { icon: 'w-4 h-4', base: 'h-7 w-7' },
            sm: { icon: 'w-4 h-4', base: 'h-8 w-8' },
            md: { icon: 'w-5 h-5', base: 'h-9 w-9' },
            lg: { icon: 'w-6 h-6', base: 'w-10 h-10' },
            xl: { icon: 'w-5 h-5', base: 'w-12 h-12' },
        },
        disabled: { true: 'opacity-40 pointer-events-none' },
        center: { true: 'items-center justify-center' },
    },
    compoundVariants: [
        {
            variant: 'fill',
            intent: 'primary',
            className: [
                'text-white bg-primary-accent',
                'hover:bg-primary-highlight',
                'active:bg-primary-interact',
                'data-selected:bg-primary-highlight',
            ].join(' '),
        },
        {
            variant: 'fill',
            intent: 'neutral',
            className: [
                'text-content-deselected bg-surface-muted',
                'hover:bg-surface-selected hover:text-content-selected',
                'active:text-content',
                'data-selected:text-content-selected data-selected:bg-surface-selected',
            ].join(' '),
        },
        {
            variant: 'ghost',
            intent: 'primary',
            className: [
                'text-primary',
                'hover:text-white hover:bg-primary-highlight',
                'active:text-white active:bg-primary-interact',
                'data-selected:text-white data-selected:bg-primary-highlight',
            ].join(' '),
        },
        {
            variant: 'ghost',
            intent: 'neutral',
            className: [
                'text-content-deselected',
                'hover:text-content hover:bg-surface-muted',
                'active:text-content active:bg-surface-muted ',
                'data-selected:text-content data-selected:bg-surface-muted',
            ].join(' '),
        },
    ],
});
