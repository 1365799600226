import React, { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

type HistoryContextState = {
    history: string[];
    setHistory(data: string[]): void;
    back(fallbackRoute?: string): void;
};

const HistoryContext = createContext<HistoryContextState>({} as HistoryContextState);

const HistoryProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const { asPath, push, pathname } = useRouter();
    const [history, setHistory] = useState<string[]>([]);

    /**
     * @param fallbackRoute
     */
    const back = (fallbackRoute?: string): Promise<boolean> => {
        for (let i = history.length - 2; i >= 0; i--) {
            const route = history[i];

            if (!route) {
                continue;
            }

            if (!route.includes('#') && route !== pathname) {
                // if you want to pop history on back
                const newHistory = history.slice(0, i);
                setHistory(newHistory);

                return push(route);
            }
        }

        if (fallbackRoute) {
            return push(fallbackRoute);
        }

        return Promise.reject();
    };

    useEffect(() => {
        // Save recent 4 history urls
        setHistory([...history.slice(history.length - 5), asPath]);
    }, [asPath]);

    return (
        <HistoryContext.Provider
            value={{
                back,
                history,
                setHistory,
            }}
        >
            {children}
        </HistoryContext.Provider>
    );
};

const useHistory = (): HistoryContextState => useContext(HistoryContext);

export { HistoryProvider, HistoryContext, useHistory };
export type { HistoryContextState };
