import type { AnyObj } from '../../../typescript';
import { isServerSide, tryJsonParse } from '../../../utils';
import { UserAgent } from '../create-api/user-agent';
import { HttpMethod } from '../http-method';

import { type FetcherOptions, type RequestOptions } from './fetcher.model';

/**
 * A custom class for `fetch` errors which extends `Error`.
 * The main advantage is to be able to easily access the response `status`
 * and the response `body`.
 *
 * @example
 * ```ts
 * try {
 *  callFetcher(...)
 * } catch (err) {
 *  const { status, errors, field, message, response } = err as FetcherError;
 *
 *  console.log(status, message)
 *  // log the original response body
 *  console.log(response)
 *  // log when there is a single error
 *  field && console.log(field)
 *  // or log when there are multiple errors
 *  errors && console.table(errors);
 *
 * }
 * ```
 * */
class FetcherError extends Error {
    /** Original response body from a fetch. */
    response: AnyObj | string | undefined;

    /** Status code returned from a fetch. */
    status: Response['status'];

    /** Success flag conveying whether a fetch failed or not. Will always be `false`. */
    success: false;

    /** Possible message for dev */
    devMessage?: string;

    /** Possible error message from server */
    error?: string;

    /** The error code attached to the error */
    errorCode?: number;

    /** Possibly message to display to user */
    userMessage?: string;

    more?: any;

    constructor(status: Response['status'], response?: AnyObj | string) {
        super();
        this.response = response;
        this.status = status;
        this.success = false;
        this.message = 'An unexpected error occured. Please try again later.';

        if (response) {
            if (typeof response === 'string') {
                this.message = response;
            } else {
                /** dynamically assign available fields: `message`, `errors`, field`
                 * and any other unknown KVP's in a given response */
                Object.assign(this, response);
            }
        }
    }
}

/**
 *
 * Gracefully handle  api errors ***AND*** network errors
 * @see https://tanstack.com/query/latest/docs/react/guides/query-functions#usage-with-fetch-and-other-clients-that-do-not-throw-by-default
 *
 * solution partially inspired by the following posts
 * @link https://stackoverflow.com/a/62774613
 * @link https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
 */
const parseFetchResponse = async <Res>(res: Response): Promise<FetcherError | Res> => {
    if (res?.ok) {
        const json = await res.json();
        /** sometimes an API responds with a valid status, but there is an error */
        if (json?.errorCode && json?.error) {
            console.warn('Possible error in fetcher', json);
        }

        return json;
    }

    const textResponse = await res.text();
    const { parsedJson } = tryJsonParse(textResponse);

    return Promise.reject(new FetcherError(res?.status, parsedJson || textResponse));
};

type GetFetchOptionsOpts = {
    method: HttpMethod;
} & Pick<FetcherOptions<any, any>, 'headers' | 'sessionToken' | 'body' | 'serverSideUserAgent'>;

const getFetchOptions = ({
    method,
    body,
    serverSideUserAgent,
    sessionToken,
    headers,
}: GetFetchOptionsOpts): RequestOptions => {
    const internalOptions: RequestOptions = {
        mode: 'cors',
        method: method || HttpMethod.Get,
        credentials: 'include',
        referrerPolicy: 'no-referrer',
    };

    /**
     * 1) Let's determine options
     * */
    if (body) {
        if (body instanceof FormData) {
            internalOptions.body = body;
        } else if (typeof body === 'object') {
            internalOptions.body = JSON.stringify(body);
        } else {
            internalOptions.body = body;
        }
    }

    const isPoster = method === HttpMethod.Patch || method === HttpMethod.Post || method === HttpMethod.Put;
    if (isPoster) {
        internalOptions.cache = 'no-cache';
        internalOptions.redirect = 'follow';
    }

    const clientHeaders: RequestInit['headers'] = {};

    if (!isServerSide()) {
        const researchSessionToken = window.sessionStorage.getItem('sessionToken');
        if (researchSessionToken) {
            clientHeaders['X-Research-Session-Token'] = researchSessionToken;
        }
    }

    /**
     * 2) Let's determine headers
     * */
    const internalHeaders: RequestInit['headers'] = {
        ...clientHeaders,
        ...(sessionToken && { 'X-Research-Session-Token': sessionToken }),
    };

    /** 👇 for `FormData` body, content type is automagically inferred */
    if (internalOptions?.body instanceof FormData) {
        /** 👇 so let's set `Accept` header to: */
        internalHeaders.Accept = 'application/json';
    } else {
        /** 👇 or by default, let's set `Content-Type` to: */
        internalHeaders['Content-Type'] = 'application/json';
    }

    if (isServerSide()) {
        /** for cloudflare / DDOS */
        internalHeaders['User-Agent'] =
            serverSideUserAgent ?? process.env.NEXT_PUBLIC_FETCHER_USER_AGENT ?? UserAgent.Research;
    }

    return {
        ...internalOptions,
        headers: {
            ...internalHeaders,
            ...(headers || {}),
        },
    };
};

export { getFetchOptions, parseFetchResponse };
