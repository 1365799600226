import React, { forwardRef } from 'react';

import { Text } from '../../text';

type ModalTitleProps = React.PropsWithChildren;

const ModalTitle = forwardRef<HTMLDivElement, ModalTitleProps>(function ModalTitle({ children }, ref) {
    return (
        <Text size="lg" weight="semibold" ref={ref}>
            {children}
        </Text>
    );
});

export type { ModalTitleProps };
export { ModalTitle };
