import { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';

import { Menu } from '@blockworks/ui/components';

const researchFilters = [
    {
        children: 'All',
        href: `/research`,
        isActive: (path: string, query: ParsedUrlQuery) =>
            path.startsWith('/research/') ||
            (path.split('?')[0] === '/research' && query.type !== 'flashnotes' && query.type !== 'reports'),
    },
    {
        children: 'Reports',
        href: `/research?type=reports`,
        isActive: (path: string, query: ParsedUrlQuery) =>
            path.startsWith('/reports/') || (path.split('?')[0] === '/research' && query.type === 'reports'),
    },
    {
        children: 'Flashnotes',
        href: `/research?type=flashnotes`,
        isActive: (path: string, query: ParsedUrlQuery) =>
            path.startsWith('/flashnotes/') || (path.split('?')[0] === '/research' && query.type === 'flashnotes'),
    },
];

const ResearchMenu = () => {
    const { asPath, query } = useRouter();
    return (
        <Menu sub>
            <Menu.List>
                {researchFilters?.map(item => (
                    <Menu.Item
                        active={item.isActive?.(asPath, query)}
                        key={`assets-${item.href}`}
                        id={item.href}
                        {...item}
                    />
                ))}
            </Menu.List>
        </Menu>
    );
};

export default ResearchMenu;
