import { startCase } from 'lodash-es';
import { SessionUser } from 'next-auth';

export const mapUserToAnalyticsUser = (user: SessionUser) => {
    const analyticsUser: Record<string, string> = {};
    const keys = Object.keys(user).filter(key => key !== 'email');
    keys.forEach(key => {
        if (key === 'firstname') key = 'first name';
        if (key === 'lastname') key = 'last name';
        analyticsUser[startCase(key)] = user[key as keyof SessionUser];
    });
    analyticsUser.$email = user.email;
    analyticsUser.$name = `${user.firstname} ${user.lastname}`;
    return analyticsUser;
};
