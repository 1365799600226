import { tVariants } from '../../style-system';

const tagStyles = tVariants({
    base: 'uppercase text-xxs w-max px-2 py-0.5 rounded-full cursor-pointer transition-colors inline-flex items-center gap-1',
    variants: {
        iconOnly: { true: 'pr-1 pl-1' },
        variant: {
            primary: 'text-primary-content bg-primary-surface capitalize hover:bg-primary-interact',
            default: 'text-content-selected bg-surface-muted hover:text-content hover:bg-surface-selected',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export { tagStyles };
