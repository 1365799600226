import { useId, useMemo } from 'react';
import { useRouter } from 'next/router';

import { openNewTab } from '@blockworks/platform/utils';
import { Bit } from '@blockworks/ui/bit';
import { Divider, FlexBox, IconButton, Link, Menu, MenuItemProps, SidebarNav, Text } from '@blockworks/ui/components';
import {
    BarChartSquareIcon,
    CoinsIcon,
    HomeIcon,
    LineChartUpIcon,
    MessageTextSquareIcon,
    SocialDuneIcon,
    SocialTelegramIcon,
    SocialXIcon,
    UserCircleIcon,
} from '@blockworks/ui/icon';

import { assetQueries } from '@/api/assets/queries';
import { AppRoutes } from '@/api/auth/auth.routes';
import { ToggleTheme } from '@/components/theme/toggle-theme';
import AnalyticsMenu from '@/layout/global/sidebar/analytics-menu/analytics-menu';
import AssetMenu from '@/layout/global/sidebar/asset-menu/asset-menu';
import AssetSubMenu from '@/layout/global/sidebar/asset-submenu/asset-submenu';
import GovHubMenu from '@/layout/global/sidebar/gov-hub-menu/gov-hub-menu';
import ResearchMenu from '@/layout/global/sidebar/research-menu/research-menu';
import { EDITORIAL_URL } from '@/utils/constants';

const nav = {
    primary: [
        {
            children: 'Home',
            icon: HomeIcon,
            href: AppRoutes.HOME.pathname,
            isActive: (path: string) => path.split('?')[0] === AppRoutes.HOME.pathname,
        },
        {
            children: 'Assets',
            icon: CoinsIcon,
            href: AppRoutes.ASSETS.pathname,
            isActive: (path: string) => path.split('?')[0] === AppRoutes.ASSETS.pathname,
            submenu: {
                type: 'accordion',
                component: AssetMenu,
            },
        },
        {
            type: 'select',
            href: AppRoutes.ASSETS.pathname,
            isActive: (path: string) => path.startsWith('/assets/'),
            submenu: {
                type: 'accordion',
                component: AssetSubMenu,
            },
        },
        {
            children: 'Research',
            icon: BarChartSquareIcon,
            href: AppRoutes.RESEARCH.pathname,
            submenu: {
                type: 'accordion',
                component: ResearchMenu,
            },
        },
        {
            children: 'GovHub',
            icon: MessageTextSquareIcon,
            href: AppRoutes.GOVERNANCE.pathname,
            submenu: {
                type: 'accordion',
                component: GovHubMenu,
            },
        },
        {
            children: 'Analytics',
            icon: LineChartUpIcon,
            href: AppRoutes.ANALYTICS.pathname,
            submenu: {
                type: 'accordion',
                component: AnalyticsMenu,
            },
        },
        {
            children: 'Analysts',
            icon: UserCircleIcon,
            href: AppRoutes.TEAM.pathname,
        },
    ],
    secondary: [
        {
            children: 'Glossary',
            href: AppRoutes.GLOSSARY.pathname,
        },
        {
            children: 'Newsletter',
            href: `${EDITORIAL_URL}/newsletter/research`,
        },
        {
            children: 'Podcast',
            href: AppRoutes.PODCASTS.pathname,
        },
        {
            children: 'Support',
            href: AppRoutes.SUPPORT.pathname,
        },
        {
            children: 'Share Feedback',
            href: 'https://blockworks.canny.io/research',
        },
    ],
    social: [
        { icon: SocialXIcon, children: 'Twitter', href: 'https://twitter.com/blockworksres' },
        { icon: SocialTelegramIcon, children: 'Discord', href: 'https://t.me/+_WvUmtaoOcFjZGNl' },
        { icon: SocialDuneIcon, children: 'Dune', href: 'https://dune.com/blockworks_research' },
    ],
} satisfies Record<string, Partial<MenuItemProps & { type?: 'select'; isActive?: (path: string) => boolean }>[]>;

const Sidebar = () => {
    const id = useId();
    const { push, asPath, query } = useRouter();
    const isRoot = asPath === '/';
    const activeRoute = nav.primary?.find(item => {
        if (item.href === '/') return isRoot;
        if (asPath.startsWith('/assets/')) return item.type === 'select';
        return asPath === item.href || (asPath.startsWith(item.href!) && item.href);
    });

    const slug = Array.isArray(query.slug) ? query.slug[0]! : query.slug!;
    const asset = assetQueries.getAsset.use({
        variables: { slug },
        enabled: asPath.startsWith('/assets/'),
        select: assetQueries.getAsset.select.assetData,
    });

    const supportedAssets = assetQueries.getAssets.use({
        variables: { isSupported: true },
        select: assetQueries.getAssets.select.assetsAndTotal,
    });

    const assetMenu = useMemo(() => {
        /**  whether the `selectedAsset` exists in the list of `supported: true` assets or not */
        let selectedAssetFound = false;
        const selectedAsset = asset?.data;

        const currentOptions =
            supportedAssets.data?.assets
                .map(asset => {
                    if (selectedAsset?.id === asset.id) {
                        selectedAssetFound = true;
                    }
                    return {
                        image: asset.imageUrl,
                        value: asset.code.toLowerCase(),
                        label: asset.title,
                    };
                })
                .sort((a, b) => (a.label > b.label ? 1 : -1)) ?? [];

        if (!selectedAssetFound && selectedAsset?.code) {
            const { code, title, imageUrl } = selectedAsset;
            currentOptions.push({ value: code?.toLowerCase(), label: title, image: imageUrl });
        }

        return { options: currentOptions, selected: selectedAsset?.code?.toLowerCase() };
    }, [asset, supportedAssets]);

    return (
        <SidebarNav activeRoute={activeRoute?.href}>
            <FlexBox h="full" col justifyContent="between">
                <Menu.List data-testid="sidebar-menu">
                    {nav.primary?.map(
                        ({
                            type,
                            children,
                            href,
                            icon,
                            submenu,
                            isActive = path => (href ? path.startsWith(href) : false),
                        }) => {
                            if (activeRoute?.type === 'select' && type === 'select') {
                                return (
                                    <Menu.Select
                                        onChange={next => push(href ? `${href}/${next}` : asPath.replace(slug, next))}
                                        selected={assetMenu.selected!}
                                        options={assetMenu.options}
                                        key={`${id}-primary-nav-${asPath}`}
                                        submenu={() => <AssetSubMenu />}
                                    />
                                );
                            }
                            if (type === 'select') {
                                return null;
                            }

                            return (
                                <Menu.Item
                                    key={`${id}-primary-nav-${children}-${asPath}`}
                                    active={isActive(asPath)}
                                    icon={icon}
                                    href={href}
                                    data-testid={`${`${children}`.toLowerCase()}-dropdown`}
                                    submenu={submenu}
                                >
                                    {children}
                                </Menu.Item>
                            );
                        },
                    )}
                </Menu.List>
                <FlexBox col>
                    <Divider orientation="horizontal" />
                    <Menu.List>
                        {nav?.secondary?.map(item => (
                            <Menu.Item
                                active={asPath.startsWith(item?.href!)}
                                key={`${id}-secondary-nav-${item.children}-${asPath}`}
                                size="xs"
                                {...item}
                            >
                                {item.children}
                            </Menu.Item>
                        ))}
                    </Menu.List>
                    <Divider orientation="horizontal" />
                    <FlexBox gap="lg" center>
                        {nav?.social?.map(
                            ({ icon, children, href }) =>
                                icon && (
                                    <IconButton
                                        key={`${id}-social-${children?.toString()}`}
                                        aria-label={`${children?.toString()}`}
                                        variant="ghost"
                                        size="lg"
                                        icon={icon}
                                        onClick={openNewTab.with(href)}
                                    />
                                ),
                        )}
                    </FlexBox>
                    <Divider orientation="horizontal" />
                    <FlexBox center pt={1}>
                        <ToggleTheme />
                    </FlexBox>
                    <Bit.div p={3} pb={5}>
                        <Text size="xxs" color="deselect">
                            © {new Date().getFullYear()} Blockworks Research.
                        </Text>
                        <Text size="xxs" color="deselect">
                            All rights reserved.{' '}
                            <Link size="xxs" textDecoration="underline" href="/terms-of-use">
                                Terms
                            </Link>
                        </Text>
                    </Bit.div>
                </FlexBox>
            </FlexBox>
        </SidebarNav>
    );
};

export default Sidebar;
