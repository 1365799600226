import { useEffect } from 'react';
import { SessionContextValue } from 'next-auth/react';

import { useTheme } from '@/context/theme';

export const useOptimisticTheme = ({ data: session, status }: SessionContextValue) => {
    const { setTheme } = useTheme();
    const userTheme = session?.user?.colorTheme;

    useEffect(() => {
        if (status !== 'loading' && userTheme) {
            setTheme(userTheme);
        }
    }, [status, userTheme, setTheme]);
};
