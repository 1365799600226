import React, { useEffect, useRef, useState } from 'react';
import { useInstantSearch, useSearchBox, UseSearchBoxProps } from 'react-instantsearch';

import { FlexBox, IconButton, Input } from '@blockworks/ui/components';
import { MagnifyingGlassIcon, XCloseIcon } from '@blockworks/ui/icon';

// TODO: once all indexes are merged into one index, we can clean up how we're getting DOM hit elements
// instead of querying for section class names, query individual list items by ID using "objectID" from each hit (useHits hook)
// ^ first, you'll need to set the right ID value on each "hit-item" component
const RESULTS_CLASS = '.search-results';
const HITS_QUERY = `${RESULTS_CLASS} li > div`;
const PREV_HITS_QUERY = `${RESULTS_CLASS} > div`;
const ARROW_DOWN = 'ArrowDown';
const ARROW_UP = 'ArrowUp';
const ENTER = 'Enter';
interface SearchInputProps extends UseSearchBoxProps {
    resultsRef: React.RefObject<HTMLDivElement>;
}

export const SearchInput = (props: SearchInputProps) => {
    const { resultsRef } = props;
    const { query, refine } = useSearchBox(props);
    const { status } = useInstantSearch();
    const [inputValue, setInputValue] = useState(query);
    const inputRef = useRef<HTMLInputElement>(null);
    const [focusedIndex, setFocusedIndex] = useState<number>();
    const focusedIndexRef = useRef<HTMLDivElement>();
    const isSearchStalled = status === 'stalled';
    const isResetHidden = inputValue.length === 0 || isSearchStalled;

    useEffect(() => {
        const domQuery = query?.length ? HITS_QUERY : PREV_HITS_QUERY;
        const hits = document.querySelectorAll(domQuery);
        if (hits?.length && typeof focusedIndex !== 'undefined') {
            for (const h of hits) {
                h.ariaSelected = 'false';
            }
            // Find index by wrapping around the length of hits
            const hitIndex = ((focusedIndex % hits.length) + hits.length) % hits.length;
            const hit = hits[hitIndex] as HTMLDivElement;
            if (hit) {
                hit.ariaSelected = 'true';
                hit.scrollIntoView({ block: 'nearest' });
                focusedIndexRef.current = hit;
            }
        }
    }, [focusedIndex]);

    const setQuery = (newQuery: string) => {
        setInputValue(newQuery);
        refine(newQuery);
        setFocusedIndex(undefined);
        resultsRef.current?.scrollTo({ top: 0 });
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ([ARROW_DOWN, ARROW_UP, ENTER].includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (e.key === ARROW_DOWN) {
            setFocusedIndex(prev => (prev ?? -1) + 1);
        } else if (e.key === ARROW_UP) {
            setFocusedIndex(prev => (prev ?? 0) - 1);
        } else if (e.key === ENTER && focusedIndex !== null) {
            if (focusedIndexRef.current) {
                focusedIndexRef.current.click();
            }
        }
    };

    return (
        <FlexBox px={3} bgColor="raised" h="full" borderBottom={1} borderColor="selected" alignItems="center">
            <form
                action=""
                role="search"
                className="flex w-full align-center items-center"
                noValidate
                onSubmit={event => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (inputRef.current) {
                        inputRef.current.blur();
                    }

                    setQuery(inputRef.current?.value ?? '');
                }}
                onReset={event => {
                    event.preventDefault();
                    event.stopPropagation();

                    setQuery('');

                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }}
            >
                <Input
                    ref={inputRef}
                    icon={MagnifyingGlassIcon}
                    size="lg"
                    w="full"
                    data-testid="search-input"
                    variant="transparent"
                    noOutline
                    name="search-field"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder="Search for assets, articles, proposals and more..."
                    spellCheck={false}
                    maxLength={512}
                    type="search"
                    value={inputValue}
                    onKeyDown={handleInputKeyDown}
                    onChange={event => {
                        setQuery(event.currentTarget.value);
                    }}
                    autoFocus
                />
                <IconButton
                    icon={XCloseIcon}
                    type="reset"
                    data-testid="search-reset-button"
                    borderRadius="full"
                    display={isResetHidden ? 'hidden' : undefined}
                    aria-label="reset"
                />
            </form>
        </FlexBox>
    );
};
