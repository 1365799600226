import React from 'react';
import NextLink from 'next/link';

import { FlexBox, Link, Logo, MiniLogo } from '@blockworks/ui/components';

import { useAuth } from '@/context/auth/auth.provider';
import { usePrevious } from '@/hooks/use-previous';

import GlobalSearch from './global-search/global-search';
import UserDropdown from './user-dropdown/user-dropdown';
import { DrawerMenu } from './drawer-menu';
import GasPricePopover from './gas-price-popover';
import NotificationPopover from './notification-popover';

const TopBar = () => {
    const { status } = useAuth();
    const previousStatus = usePrevious(status);
    const isAuthenticated = status === 'authenticated';
    const isRefreshing = status === 'loading' && previousStatus === 'authenticated';

    if (isAuthenticated || isRefreshing) {
        return (
            <>
                <FlexBox w="full" alignItems="center">
                    <DrawerMenu />
                    <FlexBox
                        display={{ initial: 'hidden', xs: 'flex', md: 'hidden' }}
                        alignItems="center"
                        gap="sm"
                        pl={3}
                    >
                        <NextLink href="/">
                            <Logo />
                        </NextLink>
                    </FlexBox>
                    <FlexBox display={{ initial: 'block', xs: 'hidden' }} pl={3}>
                        <NextLink href="/">
                            <MiniLogo />
                        </NextLink>
                    </FlexBox>
                    <FlexBox display={{ initial: 'hidden', md: 'block' }} w="full">
                        <GlobalSearch />
                    </FlexBox>
                </FlexBox>
                <FlexBox h="full" gap={{ initial: 'xs', sm: 'md' }} alignItems="center">
                    <NotificationPopover />
                    <GasPricePopover />
                    <UserDropdown />
                </FlexBox>
            </>
        );
    }

    return (
        <>
            <FlexBox w="full" alignItems="center">
                <FlexBox display={{ initial: 'hidden', xs: 'flex', md: 'hidden' }} alignItems="center" gap="sm" pl={3}>
                    <Link href="/">
                        <Logo />
                    </Link>
                </FlexBox>
            </FlexBox>
            <FlexBox h="full" gap={{ initial: 'xs', sm: 'md' }} alignItems="center">
                <GasPricePopover />
                <Link variant="fill" intent="primary" w="max" size="xs" href="/sign-in" disabled={status === 'loading'}>
                    Sign In
                </Link>
            </FlexBox>
        </>
    );
};

export default TopBar;
