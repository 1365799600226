import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Session, SessionUser } from 'next-auth';
import { SessionProvider, useSession } from 'next-auth/react';

import { useIsomorphicLayoutEffect } from '@blockworks/platform/hooks';
import { useIdentify, useReset } from '@blockworks/platform/services/analytics';

import { getAuthorizationRedirect, getPermissionLevel } from '@/api/auth/auth.guard';
import { authQueries } from '@/api/auth/queries';
import { AuthContextValue } from '@/context/auth/auth.model';
import { useOptimisticTheme } from '@/context/auth/auth.utils';
import { ThemeProvider } from '@/context/theme';

import { mapUserToAnalyticsUser } from './auth.mapper';

const AuthContext = createContext<AuthContextValue>(undefined!);

const SessionConsumer = ({ children }: PropsWithChildren) => {
    const router = useRouter();
    const session = useSession();
    const identify = useIdentify();
    const reset = useReset();
    const [identifiedUser, setIdentifiedUser] = useState<SessionUser | null>(null);
    const { data } = session;
    const user = data?.user;

    useOptimisticTheme(session);

    // For analytics
    useEffect(() => {
        if (identifiedUser === null && user !== null && user?.id) {
            setIdentifiedUser(user);
            identify?.(user.id, mapUserToAnalyticsUser(user));
        } else if (identifiedUser !== null && user === null) {
            setIdentifiedUser(null);
            reset?.();
        }
    }, [user, identify, reset]);

    authQueries.update.use({
        variables: { type: 'refresh' },
        refetchInterval: 1000 * 60 * 5,
    });

    const sessionCtx = useMemo(
        () => ({
            ...session,
            user,
            permission: getPermissionLevel(user!),
        }),
        [session, user],
    );

    useIsomorphicLayoutEffect(() => {
        if (sessionCtx.status === 'loading') return;
        const [pathname] = router.asPath.split('?');
        const { destination, params } = getAuthorizationRedirect(sessionCtx?.user!, pathname!);

        if (destination && destination !== pathname) {
            router.push({
                pathname: destination,
                query: params,
            });
        }
    }, [sessionCtx, router]);

    useIsomorphicLayoutEffect(() => {
        if (typeof window !== 'undefined' && user?.sessionToken) {
            window.sessionStorage.setItem('sessionToken', user?.sessionToken);
        }
    }, [user?.sessionToken]);

    return <AuthContext.Provider value={sessionCtx}>{children}</AuthContext.Provider>;
};

interface AuthProviderProps extends PropsWithChildren {
    initialSession?: Session | null;
}

export const AuthProvider = ({ children, initialSession }: AuthProviderProps) => {
    // If the initial session is null it will not trigger an iniitial fetch
    // this ensures that sessions thought to be unauthenticated are validated immediately with the backend
    const session = initialSession ?? undefined;
    // This value is used for the toggle / any components that may have differing server / client behavior like Radix components
    const initialTheme = session?.user?.colorTheme;
    return (
        <ThemeProvider initialTheme={initialTheme}>
            <SessionProvider session={session}>
                <SessionConsumer>{children}</SessionConsumer>
            </SessionProvider>
        </ThemeProvider>
    );
};

export const useAuth = () => useContext(AuthContext);
