import React, { forwardRef } from 'react';

import { XCloseIcon } from '@blockworks/ui/icon';

import { cn } from '../../../style-system';
import { Divider } from '../../divider';
import { FlexBox } from '../../flex-box';
import { IconButton } from '../../icon-button';

import { ModalClose } from './modal-close';

type ModalHeaderProps = React.PropsWithChildren<{
    hideCloseButton?: boolean;
}>;

const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>(function ModalHeader(
    { children, hideCloseButton = false },
    ref,
) {
    return (
        <>
            <FlexBox ref={ref} w="full" justifyContent="between" alignItems="center" pl={4} pr={2} pt={2}>
                {children}
                {!hideCloseButton && (
                    <ModalClose>
                        <IconButton variant="ghost" icon={XCloseIcon} aria-label="Close Dialog" />
                    </ModalClose>
                )}
            </FlexBox>
            <div className={cn('w-full')}>
                <Divider orientation="horizontal" mb={0} />
            </div>
        </>
    );
});

export type { ModalHeaderProps };
export { ModalHeader };
