import { kv, VercelKV } from '@vercel/kv';
import { SessionUser } from 'next-auth';

import { validateToken } from '@/api/auth/auth.schema';

const TTL_30_DAY = 60 * 60 * 24 * 30; // 30 days
class AuthStore {
    private store: VercelKV | undefined;

    isAvailable = Boolean(process.env.KV_REST_API_URL && process.env.KV_REST_API_TOKEN);

    constructor() {
        if (this.isAvailable) {
            this.store = kv;
        } else {
            this.store = undefined;
        }
    }

    getKey(session: Pick<SessionUser, 'email'>) {
        const email = session?.email;
        return `auth:session:${email}`;
    }

    async get(session: Pick<SessionUser, 'email'> | null): Promise<SessionUser | null> {
        if (!session) return null;
        const key = this.getKey(session);
        const cachedSession = await this.store?.get<SessionUser>(key);
        return cachedSession ?? null;
    }

    async set(session: SessionUser) {
        const validated = validateToken(session);
        const key = this.getKey(session);
        await this.store?.set<SessionUser>(key, validated, { ex: TTL_30_DAY });
        return validated;
    }

    async del(session?: SessionUser | null) {
        if (!session) return undefined;
        try {
            const key = this.getKey(session);
            await this.store?.del(key);
        } catch (e) {
            console.warn('Error deleting session');
        }
        return undefined;
    }
}

export const SessionManager = new AuthStore();
