import { forwardRef, PropsWithChildren } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';

import { TVStyleProps } from '../../style-system';

import { drawerStyles } from './drawer.styles';

interface DrawerProps extends PropsWithChildren {
    open: boolean;
}

const Drawer = ({ children, open }: Pick<DrawerProps, 'children' | 'open'>) => {
    return <RadixDialog.Root open={open}>{children}</RadixDialog.Root>;
};

interface DrawerContentProps extends PropsWithChildren, TVStyleProps<typeof drawerStyles, true> {
    onInteractOutside?: (open: boolean) => void;
}

const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
    ({ children, side = 'right', onInteractOutside, ...rest }, ref) => {
        const handleOnClickOutside = () => onInteractOutside?.(false);
        const { base, overlay } = drawerStyles();
        return (
            <RadixDialog.Portal>
                <div>
                    <RadixDialog.Overlay className={overlay()} />
                    <RadixDialog.Content
                        ref={ref}
                        onInteractOutside={handleOnClickOutside}
                        className={base({ side, ...rest })}
                    >
                        {children}
                    </RadixDialog.Content>
                </div>
            </RadixDialog.Portal>
        );
    },
);

DrawerContent.displayName = 'DrawerContent';

const DrawerClose = forwardRef<HTMLButtonElement, PropsWithChildren>(({ children }, ref) => (
    <RadixDialog.Close ref={ref} asChild>
        {children}
    </RadixDialog.Close>
));

DrawerClose.displayName = 'DrawerClose';

const DrawerTrigger = forwardRef<HTMLButtonElement, PropsWithChildren>(({ children }, ref) => (
    <RadixDialog.Trigger ref={ref} asChild>
        {children}
    </RadixDialog.Trigger>
));

DrawerTrigger.displayName = 'DrawerTrigger';

Drawer.Content = DrawerContent;
Drawer.Close = DrawerClose;
Drawer.Trigger = DrawerTrigger;

export type { DrawerContentProps, DrawerProps };
export { Drawer };
