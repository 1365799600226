import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const drawerStyles = tVariants(
    {
        base: 'fixed will-change-transform top-0 bottom-0 w-auto bg-surface focus:outline-none z-0 overflow-hidden',
        slots: {
            overlay: `drawerOverlay fixed inset-0 bg-black opacity-0 z-0 transition-opacity data-[state=open]:opacity-40 data-[state=closed]:opacity-0`,
        },
        variants: {
            ...factoryStyleProps.space,
            w: factoryStyleProps.size.w,
            side: {
                right: `data-[state=open]:animate-slide-in-right data-[state=closed]:animate-slide-out-right right-0`,
                left: `data-[state=open]:animate-slide-in-left data-[state=closed]:animate-slide-out-left left-0`,
                bottom: `data-[state=open]:animate-slide-in-bottom data-[state=closed]:animate-slide-out-bottom bottom-0 top-auto`,
            },
        },
    },
    { responsiveVariants: true },
);

export { drawerStyles };
