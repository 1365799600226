import { useQuery } from '@tanstack/react-query';
import { createClient } from '@vercel/edge-config';

import ResearchConfig from '@/research-config';

export interface IAssetConfigItem {
    [key: string]: { analytics?: boolean; governance?: boolean };
}

export type FeatureFlags = {
    data: boolean;
    governance: boolean;
    notifications: boolean;
};

export type EdgeConfig = {
    appName?: string;
    features?: FeatureFlags;
    assetConfig?: IAssetConfigItem;
};

export type EdgeConfigContext = EdgeConfig & {
    isConfigLoading: boolean;
};

export const defaultConfig: EdgeConfigContext = {
    features: {
        governance: false,
        data: false,
        notifications: false,
    },
    assetConfig: ResearchConfig.assetConfig,
    isConfigLoading: false,
};

/**
 * Fetch single config item by key
 */
export const get = async (key: keyof EdgeConfig) => {
    const edgeConfig = createClient(process.env.EDGE_CONFIG);
    const config = await edgeConfig.get<EdgeConfig>(key);
    return config;
};

/**
 * Fetch full edge config
 */
export const getAll = async () => {
    const edgeConfig = createClient(process.env.EDGE_CONFIG);
    const config = await edgeConfig.getAll<EdgeConfig>();
    return config;
};

/**
 * Hook to fetch edge config
 */
export const useEdgeConfig = (): EdgeConfigContext => {
    const { data, isLoading: isConfigLoading } = useQuery<EdgeConfig>({
        queryKey: ['edgeConfig'],
        queryFn: () => fetch('/api/config').then(res => res.json()),
        refetchInterval: 600000, // 10 minutes
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    if (isConfigLoading || !data) {
        return { ...defaultConfig, isConfigLoading };
    }

    return {
        features: data.features,
        assetConfig: data.assetConfig,
        isConfigLoading,
        appName: data.appName,
    };
};
