import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import Knock, { Feed } from '@knocklabs/client';

import { useAuth } from '@/context/auth/auth.provider';

interface KnockProviderProps {
    children: ReactNode;
}

const KnockClientContext = createContext<Knock | null>(null);
const NotificationFeedContext = createContext<Feed | null>(null);

export const NotificationsProvider = ({ children }: KnockProviderProps) => {
    const { user } = useAuth();
    const [knockClient, setKnockClient] = useState<Knock | null>(null);
    const [notificationFeed, setNotificationFeed] = useState<Feed | null>(null);

    useEffect(() => {
        const knockApiKey = process.env.KNOCK_PUBLIC_API_KEY;
        const knockFeedChannelId = process.env.KNOCK_FEED_CHANNEL_ID;
        if (knockApiKey && knockFeedChannelId && user?.email) {
            // Create a new Knock client and authenticate the user
            const client = new Knock(knockApiKey);
            client.authenticate(user.email);
            setKnockClient(client);

            const feed = client.feeds.initialize(knockFeedChannelId, {
                auto_manage_socket_connection: true,
            });
            setNotificationFeed(feed);
        }
    }, [user]);

    return (
        <KnockClientContext.Provider value={knockClient}>
            <NotificationFeedContext.Provider value={notificationFeed}>{children}</NotificationFeedContext.Provider>
        </KnockClientContext.Provider>
    );
};

export const useKnockClient = (): Knock | null => {
    return useContext(KnockClientContext);
};

export const useNotificationFeed = (): Feed | null => {
    return useContext(NotificationFeedContext);
};
