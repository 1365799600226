export const DOMPropNames = new Set([
    'id',
    'type',
    'style',
    'title',
    'role',
    'tabIndex',
    'htmlFor',
    'width',
    'height',
    'abbr',
    'accept',
    'acceptCharset',
    'accessKey',
    'action',
    'allowFullScreen',
    'allowTransparency',
    'alt',
    'async',
    'autoComplete',
    'autoFocus',
    'autoPlay',
    'cellPadding',
    'cellSpacing',
    'challenge',
    'charset',
    'checked',
    'cite',
    'class',
    'className',
    'cols',
    'colSpan',
    'command',
    'content',
    'contentEditable',
    'contextMenu',
    'controls',
    'coords',
    'crossOrigin',
    'data',
    'dateTime',
    'default',
    'defer',
    'dir',
    'disabled',
    'download',
    'draggable',
    'dropzone',
    'encType',
    'enterKeyHint',
    'for',
    'form',
    'formAction',
    'formEncType',
    'formMethod',
    'formNoValidate',
    'formTarget',
    'frameBorder',
    'headers',
    'hidden',
    'high',
    'href',
    'hrefLang',
    'httpEquiv',
    'icon',
    'inputMode',
    'isMap',
    'itemId',
    'itemProp',
    'itemRef',
    'itemScope',
    'itemType',
    'kind',
    'label',
    'lang',
    'list',
    'loop',
    'manifest',
    'max',
    'maxLength',
    'media',
    'mediaGroup',
    'method',
    'min',
    'minLength',
    'multiple',
    'muted',
    'name',
    'noValidate',
    'open',
    'optimum',
    'pattern',
    'ping',
    'placeholder',
    'poster',
    'preload',
    'radioGroup',
    'referrerPolicy',
    'readOnly',
    'rel',
    'required',
    'rows',
    'rowSpan',
    'sandbox',
    'scope',
    'scoped',
    'scrolling',
    'seamless',
    'selected',
    'shape',
    'size',
    'sizes',
    'slot',
    'sortable',
    'span',
    'spellCheck',
    'src',
    'srcDoc',
    'srcSet',
    'start',
    'step',
    'target',
    'translate',
    'typeMustMatch',
    'useMap',
    'value',
    'wmode',
    'wrap',
]);

export const DOMEventNames = new Set([
    'onCopy',
    'onCut',
    'onPaste',
    'onLoad',
    'onError',
    'onWheel',
    'onScroll',
    'onCompositionEnd',
    'onCompositionStart',
    'onCompositionUpdate',
    'onKeyDown',
    'onKeyPress',
    'onKeyUp',
    'onFocus',
    'onBlur',
    'onChange',
    'onInput',
    'onSubmit',
    'onClick',
    'onContextMenu',
    'onDoubleClick',
    'onDrag',
    'onDragEnd',
    'onDragEnter',
    'onDragExit',
    'onDragLeave',
    'onDragOver',
    'onDragStart',
    'onDrop',
    'onMouseDown',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
    'onPointerDown',
    'onPointerEnter',
    'onPointerLeave',
    'onPointerUp',
    'onSelect',
    'onTouchCancel',
    'onTouchEnd',
    'onTouchMove',
    'onTouchStart',
    'onAnimationStart',
    'onAnimationEnd',
    'onAnimationIteration',
    'onTransitionEnd',
]);
