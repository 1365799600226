import { forwardRef } from 'react';

import { safelySpreadDOMProps } from '../../style-system/utils';
import { AnchorElProps, InternalLinkProps, LinkBase } from '../link/link.base';

const isButtonElement = (props: any): props is ButtonElProps => {
    return typeof props.href !== 'string';
};

type ButtonElProps = React.ComponentPropsWithoutRef<'button'>;

type LinkOrButtonProps = ButtonElProps | AnchorElProps | InternalLinkProps;

const LinkOrButton = forwardRef(<T extends LinkOrButtonProps>(props: T, ref: unknown) => {
    if (isButtonElement(props)) {
        return (
            <button {...safelySpreadDOMProps(props)} ref={ref as React.Ref<HTMLButtonElement>}>
                {props.children}
            </button>
        );
    }

    return <LinkBase {...props} ref={ref as React.Ref<HTMLAnchorElement>} />;
});

LinkOrButton.displayName = 'LinkOrButton';

export type { LinkOrButtonProps };
export { LinkOrButton };
