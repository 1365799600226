import * as RadixPopover from '@radix-ui/react-popover';

import { TVStyleProps } from '../../style-system';
import { XCloseIcon } from '../icon/icons';
import { IconButton } from '../icon-button';

import { popoverStyles } from './popover.styles';

export interface PopoverProps
    extends TVStyleProps<typeof popoverStyles>,
        Pick<RadixPopover.PopoverProps, 'open' | 'onOpenChange'> {
    children: React.ReactNode;
    trigger: React.ReactNode;
    contentProps?: Omit<React.ComponentProps<typeof RadixPopover.Content>, 'className'>;
}

const PopoverClose = () => {
    return (
        <RadixPopover.Close asChild>
            <IconButton aria-label="Close" size="xs" borderRadius="lg" icon={XCloseIcon} />
        </RadixPopover.Close>
    );
};

export const Popover = ({ children, open, onOpenChange, trigger, contentProps, className }: PopoverProps) => {
    return (
        <RadixPopover.Root open={open} onOpenChange={onOpenChange}>
            <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
            <RadixPopover.Portal>
                <RadixPopover.Content
                    className={popoverStyles({ className })}
                    sideOffset={contentProps?.sideOffset ?? 5}
                    {...contentProps}
                >
                    {children}
                </RadixPopover.Content>
            </RadixPopover.Portal>
        </RadixPopover.Root>
    );
};

Popover.Close = PopoverClose;
